<style scoped lang="less">
    .allBuy{
        font-size: 0.8rem;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 36%;
    }
    .allBuy .dian{
        display: inline-block;
        width: 0.2rem;height: 0.2rem;
        background: #ED6A0C;
        border-radius: 50%;
    }
</style>
<template>
    <div class="gray-bg payment-success"  v-htmltit data-title="支付成功">
        <go-home-btn></go-home-btn>
        <div class="payment-success-box">
            <div class="payment-success-top">
                <!-- paySuccess -->
                <!-- <van-image fit="cover"
                    style="width:5rem;"
                    :src="paySuccess">
                    <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                    </template>
                </van-image> -->
                <van-icon name="checked" />
                <div style="font-weight:700;margin-left:0.5rem">支付成功</div>
            </div>
            <div class="payment-success-bottom">
                <div class="item" @click="handleClickGoHome">继续订货</div>
                <div class="item" @click="handleClickGoOrder">查看订单</div>
            </div>
        </div>
        <div class="allBuy">
            <span class="dian"></span>
            继续逛逛
            <span class="dian"></span>
        </div>
        <div>
            <good-list></good-list>
        </div>
    </div>
</template>
<script>
import './index.less'
import paySuccess from "@/assets/image/paySuccess1.png"
import goodList from "@/view/Public/goodList"
import goHomeBtn from "@/view/Public/goHomeBtn"

export default {
    name:"payment-success",
    components:{ goodList,goHomeBtn },
    data(){
        return{
            paySuccess:paySuccess,
        }
    },
    beforeRouteLeave(to,from,next){
        if(to.name == 'order'){
            to.meta.isfresh = true
        }
        next()
    },
    mounted() {
    // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0,0);
    },
    methods:{
        handleClickGoHome(){
            this.$router.push({ name:"index" })
        },
        handleClickGoOrder(){
            this.$router.push({ name:"order",params:{
                tabActive:2
            } })
        }
    }
}
</script>